@import "~bootstrap/scss/functions";

@import "./colors.scss";
@import "./typography.scss";

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

// Include any default map overrides here

@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";

@import "~bootstrap/scss/utilities/api";

// Add additional custom code here
.otp-box {
    width: 70px;
}

.custom-btn {
    cursor: pointer;
    outline: none;
    border-radius: 50px;
    background-color: transparent;
    padding: 8px 16px;
}

.custom-link {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-3px);
        color: $primary;
    }
}
